import React from 'react';
import classNames from 'classnames';

import appearance, { appearanceStyle } from '../utils/appearance';

import './layout.css';

const Layout = ({ className, page, page: { name = '' } = {}, children }) => {
	return (
		<div
			className={classNames(
				`-mb-16 bg-white z-20 relative rounded-bl-3xl rounded-br-3xl`,
				`page-${name?.toLowerCase?.()}`,
				appearance(page?.custom),
				className,
			)}
			style={appearanceStyle(page?.custom)}
		>
			<div
				className={classNames(
					`wrapper antialiased mx-auto lg:px-0`,
					page?.custom?.container,
				)}
			>
				{page?.title && (
					<div className="page-header py-52 pb-0 mx-auto justify-center flex align-center">
						<h2 className="page-title title font-medium text-2xl">
							{page.title}
						</h2>
					</div>
				)}
				<main
					className={classNames(
						`${name}`,
						'flex',
						'flex-col',
						'justify-center',
					)}
				>
					{children}
				</main>
			</div>
		</div>
	);
};

export default Layout;
